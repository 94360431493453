export const randomNames = [
  'Alice',
  'Andy',
  'Bob',
  'Bella',
  'Charlie',
  'Cara',
  'David',
  'Diana',
  'Eve',
  'Ethan',
  'Frank',
  'Fiona',
  'Grace',
  'George',
  'Harry',
  'Helen',
  'Iris',
  'Ian',
  'Jack',
  'Jill',
  'John',
  'Julia',
  'Kim',
  'Kyle',
  'Lisa',
  'Leo',
  'Maggie',
  'Max',
  'Mike',
  'Molly',
  'Naomi',
  'Nate',
  'Olivia',
  'Oscar',
  'Paul',
  'Penny',
  'Queen',
  'Quinn',
  'Robert',
  'Rachel',
  'Sarah',
  'Sam',
  'Taylor',
  'Thomas',
  'Victoria',
  'Vincent',
  'William',
  'Wendy',
  'Xavier',
  'Xena',
  'Yvonne',
  'Yuri',
  'Zoe',
  'Zack',
];
